import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import React from "react";
import aboutusdesktop from "./Group 166 (4).png";
import aboutusphone from "./Group 167 (1).png";
import "./aboutus.scss";

export default function Aboutus() {
  return (
    <>
      <Helmet>
        <title>Settlrs - About Us</title>
        <meta
          name="description"
          content="We are a Bengaluru based company, currently a part of IIM-B Startup LaunchPad. Founded in 2017, by Nishanth Janadri and Gaurav Renebennur."
        />
      </Helmet>
      <div className="about-us-image">
        <Container maxWidth="xl">
          <img className="about-us-image-desktop" src={aboutusdesktop} alt="" />
        </Container>
        <img className="about-us-image-phone" src={aboutusphone} alt="" />
      </div>
      <div className="about-us-our-story">
        <Container maxWidth="lg">
          <h2>Our Story</h2>
        </Container>
        <div className="about-us-our-story-content">
          <Container maxWidth="lg">
            <div className="about-us-our-story-content-top">
              <img
                src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/founders+image+2+(3).jpg"
                alt="founders"
                loading="lazy"
              />
              <p>
                We're a Bengaluru-based startup, born from IIM-B Startup
                LaunchPad in 2017 by two visionary engineers Gaurav Ranebennur
                and Nishanth Janadri. As a B2B player we address the market
                issues of overpriced depreciating assets and underutilized
                natural resources. By introducing a monthly rental model for
                short-term asset needs, we disrupted the CapEx market, providing
                scalability and asset management solutions through our circular
                economy approach, benefiting both businesses and the
                environment.
              </p>
            </div>
            <div className="about-us-our-story-content-bottom">
              <p>
                <strong>Gaurav & Nishanth:</strong>
                <br />
                <br /> Amidst the chaos of job hunting and startup dreams, we
                stumbled upon a brilliant idea. Living with friends who faced
                the same struggles, we noticed a common thread - the need for
                things temporarily, without the burden of ownership. That's when
                it hit us: why not start a rental service? As we delved deeper,
                we discovered that this problem extended far beyond individuals
                to businesses grappling with hefty capital expenditures and
                environmental concerns. Thus, our commercial asset rental model
                was born, offering a solution that minimized waste and maximized
                utility. Our mission? To ease financial burdens for businesses
                and foster nationwide expansion with our innovative rental
                solution. If your idea has potential, we're here to support it,
                no matter the size of your venture!
              </p>
            </div>
          </Container>
        </div>
      </div>
      <div className="milestones-container">
        <Container maxWidth="lg">
          <h2>Milestones</h2>
          <p>
            We are the premier B2B rental service provider across all asset
            classes, offering unparalleled innovation and a comprehensive
            platform for commercial products. Having facilitated the growth of
            over 50+ businesses with 90,000+ assets delivered across 18+ cities
            in India, we provide nationwide service at market-leading rental
            rates.
          </p>
          <img
            src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/Milestones.png"
            alt="milstoneimage"
          />
        </Container>
      </div>
      <div className="our-team">
        <Container maxWidth="lg">
          <h2>Our Team</h2>
          <p>
            At Settlrs, our team embodies a dynamic and collaborative work
            culture that fosters innovation and excellence. We cultivate an
            environment where creativity thrives, encouraging open communication
            and the exchange of ideas. Our supportive atmosphere empowers
            individuals to take ownership of their projects and explore new
            avenues for growth and development.
            <br />
            <br /> At the heart of our culture lies a dedication to continuous
            improvement, ensuring that we remain at the forefront of our
            industry. Join us at{" "}
            <a href="/careers" style={{ textDecoration: "underline" }}>
              www.settlrs.com/careers
            </a>{" "}
            and become part of a team where passion meets professionalism, and
            every voice is valued.
          </p>
        </Container>
      </div>
      <div className="backed-by">
        <Container maxWidth="lg">
          <h2>Backed By</h2>
          <p>
            We are backed by a great panel of investors. From raising Angel
            Round investment in the year 2019 to raising a Pre-Series - A
            funding of $1Mn from Canbank Ventures and others in the year 2022,
            we have come a long way. With new ideas of reformation every coming
            day, we are limit lessing ourselves.
          </p>
          <div className="backed-by-logos">
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/Group151.png"
              alt=""
            />
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/Group152.png"
              alt=""
            />
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/Group153.png"
              alt=""
            />
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/Group154.png"
              alt=""
            />
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/Group155.png"
              alt=""
            />
          </div>
        </Container>
      </div>
      <div className="supported-by">
        <Container maxWidth="lg">
          <h5>Supported by</h5>
          <img
            src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/aboutus/backed-by-logo/IIM_Bangalore_Logo.png"
            alt="IIMB logo"
          />
        </Container>
      </div>
    </>
  );
}
