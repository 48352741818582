import { BASEURL } from "config";
import { Button, Container } from "@mui/material";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AssetDetailsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const AssetDetailItem = styled.li`
  margin-bottom: 20px;
  font-size: clamp(0.75rem, 4vw, 1.25rem);
`;

const Title = styled.h2`
  font-size: clamp(1.25rem, 4vw, 2rem);
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

function AssetInformation() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const taggingId = urlParams.get("qr_id");
  const [assetDetails, setAssetDetails] = useState(null);
  useEffect(() => {
    async function getAssetDetails() {
      try {
        if (taggingId) {
          const response = await fetch(
            `${BASEURL}/main/view_asset_information?tagging_id=${taggingId}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setAssetDetails(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getAssetDetails();
  }, [taggingId]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalVisible(true);
  };

  return (
    <>
      <Container maxWidth="lg">
        <div>
          <Title>Asset Information</Title>
          <br />
          {assetDetails && (
            <AssetDetailsList>
              <AssetDetailItem>
                <strong>Name:</strong> {assetDetails?.name}
              </AssetDetailItem>
              <AssetDetailItem>
                <strong>Configuration:</strong> {assetDetails?.configuration}
              </AssetDetailItem>
              <AssetDetailItem>
                <strong>Serial No:</strong> {assetDetails?.serial_no}
              </AssetDetailItem>
              <AssetDetailItem>
                <strong>Deployment Status:</strong>{" "}
                {assetDetails?.deployment_status}
              </AssetDetailItem>
              <AssetDetailsList>
                {assetDetails?.current_image &&
                  Object.entries(assetDetails?.current_image).map(
                    ([key, images]) =>
                      images &&
                      images.length > 0 && (
                        <AssetDetailItem key={key}>
                          <strong>
                            {key.charAt(0).toUpperCase() + key.slice(1)} Image:
                          </strong>
                          <span style={{ display: "flex", flexWrap: "wrap" }}>
                            {images.map((imageUrl, index) => (
                              <img
                                key={index}
                                src={imageUrl}
                                alt=""
                                className="thumbnail-image"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  margin: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleClick(imageUrl)}
                              />
                            ))}
                          </span>
                        </AssetDetailItem>
                      )
                  )}
                {!assetDetails?.current_image ||
                Object.values(assetDetails?.current_image).every(
                  (images) => !images || images.length === 0
                ) ? (
                  <AssetDetailItem>No images</AssetDetailItem>
                ) : null}
              </AssetDetailsList>
              {/* Add more details as needed */}
            </AssetDetailsList>
          )}
        </div>
        <ButtonsContainer>
          <a
            href={`https://clients.settlrs.com/asset/${assetDetails?.asset_id}`}
            target="_blank" // Add target="_blank" if you want the link to open in a new tab
            rel="noopener noreferrer" // Recommended for security reasons
            style={{
              display: "inline-block",
              textDecoration: "none",
              marginRight: "10px",
            }}
          >
            <Button
              size="small"
              startIcon={<ConfirmationNumberIcon />}
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              color="error"
            >
              Raise Ticket (Client)
            </Button>
          </a>
          <a
            href={`https://internal.settlrs.com/assets/view-asset-details/${assetDetails?.id}`}
            target="_blank" // Add target="_blank" if you want the link to open in a new tab
            rel="noopener noreferrer" // Recommended for security reasons
            style={{
              display: "inline-block",
              textDecoration: "none",
            }}
          >
            <Button
              size="small"
              startIcon={<ConfirmationNumberIcon />}
              variant="outlined"
              disableElevation
              style={{ textTransform: "none" }}
              color="error"
            >
              Raise Ticket (Employee)
            </Button>
          </a>
        </ButtonsContainer>
      </Container>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={600}
      >
        <br />
        <img
          src={selectedImage}
          alt="Selected"
          style={{ width: "auto", height: "30%" }}
        />
      </Modal>
    </>
  );
}

export default AssetInformation;
