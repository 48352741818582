import { ClipLoader } from "react-spinners";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Footer from "Components/Footer/Footer";
import Navbar from "Components/Navbar/Navbar";
import ScrollToTop from "Components/ScrollToTop/ScrollToTop";

import "./layout.scss";

function Layout() {
  return (
    <>
      <div className="layout">
        <div>
          <div className="navbar">
            <Navbar />
          </div>
          <ScrollToTop />
          <div className="content">
            <LazyLoading>
              <Outlet />
            </LazyLoading>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
function LazyLoading({ children }) {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <ClipLoader color="#e96c4c" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}
