import { Container } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { Turn as Hamburger } from "hamburger-react";
import { useState } from "react";

import "./navbar.scss";

function Navbar() {
  const [open, setOpen] = useState(false);
  return (
    <div className="header">
      <Container maxWidth="lg">
        <nav>
          <div className="left">
            <Link to="/" className="logo">
              <img
                src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/settlrs-transparent-logo.png"
                alt="logo"
              />
            </Link>
            <div>
              <NavLink
                exact="true"
                to="/"
                activeclassname="active"
                className="nav-link"
              >
                Home
              </NavLink>
              <NavLink
                to="/contact-us"
                activeclassname="active"
                className="nav-link"
              >
                Contact us
              </NavLink>
              <NavLink
                to="/about-us"
                activeclassname="active"
                className="nav-link"
              >
                About us
              </NavLink>
            </div>
          </div>
          <div className="right">
            <div className="menuIcon">
              <Hamburger toggled={open} toggle={setOpen} size={20} />
            </div>
            <div className={open ? "menu active" : "menu"}>
              <a href="/">Home</a>
              <a href="/contact-us">Contact us</a>
              <a href="/about-us">About us</a>
              <a href="/case-studies">Case studies</a>
            </div>
          </div>
        </nav>
      </Container>
    </div>
  );
}

export default Navbar;
