import { Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";

import "./footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      <Container maxWidth="lg">
        <div className="footer-content-container">
          <div>
            <img
              src="https://d5r8zwzwkh326.cloudfront.net/new+ui+images/settlrs-transparent-logo.png"
              alt="logo"
            />
            <span className="social-icon">
              <a href="https://www.instagram.com/settlrs/" target="blank">
                <InstagramIcon className="icons" />
              </a>
              <a
                href="https://www.linkedin.com/company/settlrs/about/"
                target="blank"
              >
                <LinkedInIcon className="icons" />
              </a>
              <a href="https://www.facebook.com/settlrs/" target="blank">
                <FacebookIcon className="icons" />
              </a>
            </span>
          </div>
          <div>
            <h4>Useful Links</h4>
            <div className="useful-links">
              <span>
                <a href="/contact-us">Contact Us</a>
              </span>
              <span>
                <a href="/faqs">FAQs</a>
              </span>
              <span>
                <a href="/blogs">Blogs</a>
              </span>
              <span>
                <a href="/about-us">About Us</a>
              </span>
              <span>
                <a href="/careers">Careers</a>
              </span>
              <span>
                <a href="/case-studies?case=stanza">Case Studies</a>
              </span>
            </div>
          </div>
          <div>
            <h4>Reach Us</h4>
            <p>
              3rd Floor, Clayworks, Shankara Foundation
              <br />
              Munireddy Layout, Konanakunte, Kanakapura Rd
              <br />
              Bengaluru, Karnataka 560062, India
              <br />
              <br />
              <a href="tel:+917892295893">Phone : +91 7892295893</a>
              <br />
              <a href="mailto:info@settlrs.com">Email : info@settlrs.com</a>
            </p>
          </div>
        </div>
      </Container>
      <div className="footer-bottom">
        <Container maxWidth="lg">
          <div className="policy-and-copyright-text">
            <span className="copyright-text">
              &copy; {currentYear} Settlrs Venture Pvt. Ltd.
            </span>
            <span className="policy-text">
              <a href="/terms-and-conditions">Terms</a>
              <a href="/privacy-policy">Privacy</a>
            </span>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
